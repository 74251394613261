import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import StateProvider from '../providers/StateProvider';

import HomeScreen from '../screens/HomeScreen';

const Stack = createStackNavigator();

export default function AppStack(props) {
  // console.log(`user: ${JSON.stringify(props.user.email)}`)
  return (
    <StateProvider>
      <Stack.Navigator>
        <Stack.Screen name="Home" component={HomeScreen} />
      </Stack.Navigator>
    </StateProvider>

  );
}
