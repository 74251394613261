import React, { useState, useEffect } from 'react';
import Constants from 'expo-constants';
import axios from 'axios'
const REACT_APP_WEBSOCKET_URL = Constants.manifest.extra.PROD_WEBSOCKET_URL
const REACT_APP_HTTP_URL = Constants.manifest.extra.PROD_HTTP_URL


export const GameStateContext = React.createContext()

export const StateProvider = (props) => {
  const [user, setUser] = useState(null)
  const [gameState, setGameState] = React.useState(null)
  const [client, setClient] = React.useState("")
  const [connectionStatus, setConnectionStatus] = React.useState("offline")

  const connect = (user) => {
    const client = new WebSocket(REACT_APP_WEBSOCKET_URL);
    client.onopen = () => {
      // console.log('ws client connected...');
      client.send(JSON.stringify({
        type: "JOIN_GAME",
        game: user.game_id,
        username: user.name
      }));
      setClient(client)
      setConnectionStatus("online")
      setUser({ name: user.name, game_id: user.game_id })
    };

    client.onmessage = (evt) => {
      const message = JSON.parse(evt.data)
      // console.log(`received message : ${JSON.stringify(message)}`);

      if (message.type === "UPDATE_GAME_STATE") {
        // console.log(`new game state: ${message} `);
        setGameState(message.gameState)
      }
    };

    client.onclose = () => {
      // console.log('disconnected')
      setConnectionStatus('offline')
      // automatically try to reconnect on connection loss
      try {
        // console.log('trying to reconnect')
        if(user.name && user.game_id){
          // connect(user)
          // const client = new WebSocket(REACT_APP_WEBSOCKET_URL);
          // client.send(JSON.stringify({
          //   type: "JOIN_GAME",
          //   game: user.game_id,
          //   username: user.name
          // }));
          // setConnectionStatus('online')
        }
      } catch (error) {
        // console.log(`error trying to reconnect: ${error}`)
        setConnectionStatus('offline')
      }
    }

    // websocket onerror event listener
    client.onerror = err => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
      client.close();
    };
  }

  const fetchGameState = (user) => {
    return axios.post(`${REACT_APP_HTTP_URL}/api/game/`, { user })
      .then((res) => {
        res.data ? setGameState(res.data) : ''
      })
      .catch((error)=>{
        console.log(`error on fetchGameState: ${error.message}`)
      })
  }

  const resetGame = (game_id) => {
    // console.log(`restting: ${game_id}`)
    return axios.post(`${REACT_APP_HTTP_URL}/api/games/reset`, { game_id })
      .then((res) => {
        // console.log(`res.data from reset: ${res.data}`)
        if(res.data) {
          setUser(null)
          // setGameState(res.data[game_id]) 
        }
      })
      .catch((error)=>{
        console.log(`error on resetGame: ${error.message}`)
      })
  }

  const resetAllGames = (secret) => {
    return axios.post(`${REACT_APP_HTTP_URL}/api/games/reset/all`, { secret })
      .then((res) => {
        if(res.data) {
          setUser(null)
          // setGameState(res.data) 
        }
      })
      .catch((error)=>{
        console.log(`error on resetGame all: ${error.message}`)
      })
  }

  return (
    <GameStateContext.Provider
      value={{
        user,
        setUser,
        connect,
        client,
        fetchGameState,
        gameState,
        setGameState,
        connectionStatus,
        resetGame,
        resetAllGames
      }}>
      {props.children}
    </GameStateContext.Provider>
  );
};

export default StateProvider;