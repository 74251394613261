import React, { useState, useContext } from 'react';
import { StyleSheet, TouchableOpacity, Text } from 'react-native';
import * as Yup from 'yup';

import Colors from '../../utils/colors';
import SafeView from '../SafeView';
import { Formik } from 'formik';
import FormField from '../Forms/FormField';
import FormErrorMessage from '../Forms/FormErrorMessage';
import useStatusBar from '../../hooks/useStatusBar';
import { logout } from '../Firebase/firebase';

import { GameStateContext } from '../../providers/StateProvider';
import { AuthUserContext } from '../../navigation/AuthUserProvider';

const validationSchema = Yup.object().shape({
  nick_name: Yup.string()
    .required('Please enter a nick name to use')
    .label('Nick-Name'),
  game_id: Yup.string()
    .required('Please enter a game id')
    .label('Game ID')
});

export default function JoinForm({ }) {
    const {  connect, setUser } = useContext(GameStateContext);
    const auth = useContext(AuthUserContext);

  useStatusBar('light-content');

  const [joinError, setJoinError] = useState('');
  async function handleSignOut() {
    try {
      auth.setSignedOut(true)
      await logout();
    } catch (error) {
      console.log(error);
    }
  }

const handleOnJoin = (values) => {
    // console.log(`values: ${JSON.stringify(values)}`)
    let user = {name: values.name.toLowerCase().trim(), game_id: values.game_id.toLowerCase().trim()}
    // console.log(`values user: ${JSON.stringify(user)}`)
    if(!user.name) return setJoinError('Please First Enter nick name...')
    if(!user.game_id) return setJoinError('Please First Enter Game ID...')

    try {
      console.log(`connecting`)
      setUser(user)
      connect(user);
    } catch (error) {
      console.log(`error on join: ${error.message}`)
       setJoinError(error.message);
    }
  }
const generateRandomString = (nickname) => {
  let first_letter = nickname.split('').shift()
  let last_letter = nickname.split('').pop()
  let result = `${first_letter}${last_letter}`;
  let chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  for (let i = 1; i <= 4; i++) {
    result += chars[Math.round(Math.random() * (chars.length - 1))];
  }
  return result;
};

const handleOnGenerate = (name) => {
  if(!name) return setJoinError('Please First Enter nick name...')
  let game_id = generateRandomString(name)
  handleOnJoin({name, game_id})
}

  return (
    <SafeView style={styles.container}>
      <Formik
        initialValues={{ name: '', game_id: '' }}
        validationSchema={validationSchema}
      >
        {({values}) => (
          <>
        <FormField
          name="name"
          leftIcon="lock"
          placeholder="Enter nick-name"
          autoCapitalize="none"
          autoFocus={true}
        />
        <FormField
          name="game_id"
          leftIcon="lock"
          placeholder="Enter game-id"
          autoCapitalize="none"
        />
        {<FormErrorMessage error={joinError} visible={true} />}
        {/* {!auth.user.isAnonymous && */}
        <>
        <TouchableOpacity
          style={[styles.button, { backgroundColor: Colors['primary'] }]}
          onPress={() => handleOnGenerate(values.name)}
        >
          <Text style={styles.buttonText}> GENERATE GAMEID </Text>
        </TouchableOpacity>
          <Text style={styles.buttonText}>--- OR ALREADY HAVE GAMEID? ---</Text>
        </> 
        {/* } */}
        <TouchableOpacity
          style={[styles.button, { backgroundColor: Colors['primary'] }]}
          onPress={() => handleOnJoin(values)}
        >
          <Text style={styles.buttonText}> Join Game </Text>
        </TouchableOpacity>
        {/* <TouchableOpacity
          style={[styles.button, { backgroundColor: Colors['primary'] }]}
          onPress={handleSignOut}
        >
          <Text style={styles.buttonText}> Sign Out </Text>
        </TouchableOpacity> */}
        </>)}
      </Formik>
    </SafeView>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 15,
    backgroundColor: Colors.mediumGrey,
    alignItems: "center"
  },
  footerButtonContainer: {
    marginVertical: 15,
    justifyContent: 'center',
    alignItems: 'center'
  },
  forgotPasswordButtonText: {
    color: Colors.white,
    fontSize: 18,
    fontWeight: '600'
  },
  backButton: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    marginVertical: 10,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    minWidth: '100%'
  },
  buttonText: {
    color: Colors.white,
    fontSize: 18,
    fontWeight: '600',
    textTransform: 'uppercase',
  }
});
