import React, { useState, useContext } from 'react';
import {
  View,
  ImageBackground,
  Image,
  TouchableOpacity,
  Animated,
  Dimensions,
  Text,
  ScrollView
} from 'react-native';
import axios from 'axios'
import Constants from 'expo-constants';
import * as Animatable from 'react-native-animatable';
import commonStyle from '../../styles';
import cards from '../../assets/cards';
import { GameStateContext } from '../../providers/StateProvider';
const REACT_APP_WEBSOCKET_URL = Constants.manifest.extra.PROD_WEBSOCKET_URL
const REACT_APP_HTTP_URL = Constants.manifest.extra.PROD_HTTP_URL

const PlayerHand = (props) => {
  const { user, gameState } = useContext(GameStateContext);

  let initAlpha = 0;
  let cardsCount = 0;
  let angleOfCard = 7;
  let step = 4;
  let a = 90;
  let b = 200;

  const playCard = (name) => {
    let current_player = user.name
    return axios.post(`${REACT_APP_HTTP_URL}/api/game/play`, { gameState, current_player, name })
      .then((res) => {
        // console.log(`res from playCard: ${JSON.stringify(res)}`)
      })
  }


  const toRadian = (degrees) => {
    return degrees * Math.PI / 180;
  }
  cardsCount = props.playerHand.length;
  initAlpha = (20 - angleOfCard * (cardsCount - 1)) / 2;
  let beta = (180 - step * (cardsCount - 1)) / 2 - 15;
  let betaRad = toRadian(beta);
  let x = a * Math.cos(betaRad);
  let y = b * Math.sin(betaRad);
  let dx = 0;
  let dy = 0;

  const calculateDegree = () => {
    const transform = [
      {
        translateX: Number.parseInt(dx),
      },
      {
        translateY: Number.parseInt(dy),
      },
      {
        rotate: parseDegree(initAlpha),
      },
    ];
    // console.log(transform);
    beta += step;
    initAlpha += angleOfCard;
    betaRad = toRadian(beta);
    let x2 = a * Math.cos(betaRad);
    let y2 = b * Math.sin(betaRad);
    dx += x - x2;
    dy += y - y2;
    x = x2;
    y = y2;

    return transform;
  }

  const getIndentWidth = (index, length) => {
    let screenWidth = Dimensions.get('window').width;
    if (index == 0) return 0;

    let itemsWidth = length * 100;
    let diff = screenWidth - 100 - itemsWidth;

    return diff / length;
  }

  const parseDegree = (degree) => {
    return `${degree}deg`;
  }

  const getMarginTop = (rotation, i, length) => {
    if (i < length / 2) {
      return rotation * (length - i);
    } else {
      return rotation * i;
    }
  }

  const getCardWrapperStyle = (i) => {
    const rotation = calculateDegree(i, props.playerHand.length);
    const marginTop = getMarginTop(10, i, props.playerHand.length);
    const marginLeft = getIndentWidth(i, props.playerHand.length);

    return {
      marginLeft: marginLeft,
      marginTop: Math.abs(marginTop), // * direction,
      transform: rotation,
    };
  }

  return (
    <ImageBackground
      resizeMode="stretch"
      source={require('../../assets/board.png')}
      style={commonStyle.playerArea}>
      {props.gameStart ? (
        <ScrollView horizontal={true}>
          <View style={commonStyle.topArea}>
            {props.playerHand.map((card, i) => {
              return (
                <Animated.View
                  key={i}
                  style={getCardWrapperStyle(
                    i,
                    props.playerHand.length
                  )}>
                  <TouchableOpacity
                    onPress={() => playCard(card.name)}
                  >
                    <Image
                      resizeMode="stretch"
                      source={cards[card.design][`_${card.number}`]}
                      style={[commonStyle.card]}
                    />
                  </TouchableOpacity>
                </Animated.View>
              );
            })}
          </View>
        </ScrollView>
      ) : (
          <View style={commonStyle.topArea}>
            <Animatable.Text
              style={{
                fontSize: 24,
                color: 'white',
                textAlign: 'center',
              }}
              animation="pulse"
              easing="ease-out"
              iterationCount="infinite">
              {props.gameStarting
                ? 'Cards Are Dealing...'
                : 'Please Tap the Center Deck to Start the Game'}
            </Animatable.Text>
          </View>
        )}
    </ImageBackground>
  );
}

export default PlayerHand;
