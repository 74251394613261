import React, { useEffect, useContext } from 'react';
import { View, StyleSheet, Text, Clipboard, ImageBackground } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Colors from '../utils/colors';

import useStatusBar from '../hooks/useStatusBar';
import Game from '../components/Game/Game.js';
import SettingsScreen from '../components/Game/SettingsScreen.js';
import AnonSettingsScreen from '../components/Game/AnonSettingsScreen.js';
import JoinGame from '../components/Game/JoinGame.js';
import TabBarIcon from '../components/TabBarIcon';
import { GameStateContext } from '../providers/StateProvider';
import { AuthUserContext } from '../navigation/AuthUserProvider';

const BottomTab = createBottomTabNavigator();
const INITIAL_ROUTE_NAME = 'PublicFeedScreen';

export default function HomeScreen() {
  const { user, setUser, gameState, fetchGameState, connect, connectionStatus } = useContext(GameStateContext);
  const auth = useContext(AuthUserContext);

  // console.log(`user: ${JSON.stringify(user)}`)

  useStatusBar('dark-content');

  function handleLeaveGame () {
    setUser(null)
  }

  function copyToClipboard () {
    Clipboard.setString(user.game_id)
  }

  const reconnect = () => {
    if(user){
      connect(user)
      fetchGameState(user)
    }
  }
  const refresh = () => {
    fetchGameState(user)
  }

  function MainArea() {
    return <Game gameStart={gameState && gameState.status !== "starting" ? true : false} gameState={gameState && gameState.activePlayers && gameState.activePlayers[user.name] ? gameState.data : {}} />
  }

  function Settings() {
    return <>
    <SettingsScreen/>
    </>
  }
  function AnonSettings() {
    return <>
    <AnonSettingsScreen/>
    </>
  }

  useEffect(() => {
    if(user){
      fetchGameState(user)
    }
  }, [user])

  return (
    <ImageBackground
      source={require('../assets/bg.png')}
      style={{
        flex: 1,
      }}>
      <View style={styles.container}>
        {(gameState && gameState.status !== 'started' && gameState.status !== 'game ended') || !user ? <JoinGame /> :
          <>
            <View style={styles.signoutView}>
              <Icon.Button
                onPress={handleLeaveGame}
                name="ios-home"
                size={16}
                color="#fff"
                backgroundColor="#231">
                Leave Game
          </Icon.Button>
              <Text style={{maxWidth: 120, color: "#fff", fontWeight: "bold", fontSize: 16}}>{user.name} @ {user.game_id}</Text>
              <Icon.Button
                onPress={copyToClipboard}
                name="ios-copy"
                size={16}
                color="#fff"
                backgroundColor="transparent"></Icon.Button>
              {connectionStatus === "offline" ?
              <Icon.Button
                onPress={reconnect}
                name="refresh"
                size={16}
                color="#fff"
                backgroundColor="#231">
                Reconnect
              </Icon.Button> :
              <Icon.Button
                onPress={refresh}
                name="refresh"
                size={16}
                color="#fff"
                backgroundColor="#231">
                Refresh
              </Icon.Button> }
            </View>
            <BottomTab.Navigator initialRouteName={INITIAL_ROUTE_NAME}>
              <BottomTab.Screen
                name="PublicFeedScreen"
                component={MainArea}
                options={{
                  title: 'Game',
                  tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="home" />,
                }}
              />
              {!auth.user.isAnonymous ?
              <BottomTab.Screen
                name="SettingsScreen"
                component={Settings}
                options={{
                  title: 'Settings',
                  tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="settings" />,
                }}
              /> 
              :
              <BottomTab.Screen
                name="SettingsScreen"
                component={AnonSettings}
                options={{
                  title: 'Settings',
                  tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="settings" />,
                }}
              /> 

              }
            </BottomTab.Navigator>
          </>
        }
      </View>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: Colors['red'],
  },
  button: {
    marginVertical: 10,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    width: '100%'
  },
  buttonText: {
    color: Colors.white,
    fontSize: 18,
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  signoutView: {
    alignItems: 'center',
    margin: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
});
