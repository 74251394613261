const cards = {
  hearts: {
    _a: require('./card/a_of_hearts.png'),
    _2: require('./card/2_of_hearts.png'),
    _3: require('./card/3_of_hearts.png'),
    _4: require('./card/4_of_hearts.png'),
    _5: require('./card/5_of_hearts.png'),
    _6: require('./card/6_of_hearts.png'),
    _7: require('./card/7_of_hearts.png'),
    _8: require('./card/8_of_hearts.png'),
    _9: require('./card/9_of_hearts.png'),
    _10: require('./card/10_of_hearts.png'),
    _J: require('./card/j_of_hearts2.png'),
    _Q: require('./card/q_of_hearts2.png'),
    _K: require('./card/k_of_hearts2.png'),
  },
  spades: {
    _a: require('./card/a_of_spades.png'),
    _2: require('./card/2_of_spades.png'),
    _3: require('./card/3_of_spades.png'),
    _4: require('./card/4_of_spades.png'),
    _5: require('./card/5_of_spades.png'),
    _6: require('./card/6_of_spades.png'),
    _7: require('./card/7_of_spades.png'),
    _8: require('./card/8_of_spades.png'),
    _9: require('./card/9_of_spades.png'),
    _10: require('./card/10_of_spades.png'),
    _J: require('./card/j_of_spades2.png'),
    _Q: require('./card/q_of_spades2.png'),
    _K: require('./card/k_of_spades2.png'),
  },
  clubs: {
    _a: require('./card/a_of_clubs.png'),
    _2: require('./card/2_of_clubs.png'),
    _3: require('./card/3_of_clubs.png'),
    _4: require('./card/4_of_clubs.png'),
    _5: require('./card/5_of_clubs.png'),
    _6: require('./card/6_of_clubs.png'),
    _7: require('./card/7_of_clubs.png'),
    _8: require('./card/8_of_clubs.png'),
    _9: require('./card/9_of_clubs.png'),
    _10: require('./card/10_of_clubs.png'),
    _J: require('./card/j_of_clubs2.png'),
    _Q: require('./card/q_of_clubs2.png'),
    _K: require('./card/k_of_clubs2.png'),
  },
  diamonds: {
    _a: require('./card/a_of_diamonds.png'),
    _2: require('./card/2_of_diamonds.png'),
    _3: require('./card/3_of_diamonds.png'),
    _4: require('./card/4_of_diamonds.png'),
    _5: require('./card/5_of_diamonds.png'),
    _6: require('./card/6_of_diamonds.png'),
    _7: require('./card/7_of_diamonds.png'),
    _8: require('./card/8_of_diamonds.png'),
    _9: require('./card/9_of_diamonds.png'),
    _10: require('./card/10_of_diamonds.png'),
    _J: require('./card/j_of_diamonds2.png'),
    _Q: require('./card/q_of_diamonds2.png'),
    _K: require('./card/k_of_diamonds2.png'),
  },
  joker: {
    _red: require('./card/red_joker.png'),
    _black: require('./card/black_joker.png'),
  }
};

export default cards;
