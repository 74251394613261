import React, { useContext } from 'react';
import axios from 'axios'
import Constants from 'expo-constants';
import { View, TouchableOpacity, Image } from 'react-native';
import * as Animatable from 'react-native-animatable';
import GameStatusBar from './GameStatusBar';
import commonStyle from '../../styles';
import cards from '../../assets/cards';
import { GameStateContext } from '../../providers/StateProvider';
const REACT_APP_WEBSOCKET_URL = Constants.manifest.extra.PROD_WEBSOCKET_URL
const REACT_APP_HTTP_URL = Constants.manifest.extra.PROD_HTTP_URL

const GameArea = (props) => {
  const { user, setUser, connect, gameState } = useContext(GameStateContext);


  const drawCard = () => {
    // let gameState = gameState
    let current_player = user.name
    // console.log(`${current_player} picked card:`)
    return axios.post(`${REACT_APP_HTTP_URL}/api/game/draw`, { gameState, current_player })
      .then((res) => {
        // console.log(`res from drawCard: ${JSON.stringify(res)}`)
        // res.data ? setGameState(res.data) : ''
      })
  }
  let card = props.deck[props.deck.length - 1]
  return (
    <View
      style={{
        flex: 1,
      }}>
      <GameStatusBar />
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}>
        {props.deck.length > 0 ? (
          <TouchableOpacity
          // onPress={drawCard}
          >
            <Image
              resizeMode="stretch"
              // source={require('../../assets/card/back.png')}
              source={cards[card.design][`_${card.number}`]}
              style={[commonStyle.card]}
            />
          </TouchableOpacity>
        ) : null }
        {props.openDeck.length > 0 ? (
          <TouchableOpacity
            onPress={drawCard}>
            <Image
              resizeMode="stretch"
              source={require('../../assets/card/back.png')}
              // source={
              //   props.openDeck[props.openDeck.length - 1].image
              // }
              // source={cards[card.design][`_${card.number}`]}
              style={[commonStyle.card]}
            />
          </TouchableOpacity>
        ) : null}
      </View>
    </View>
  );
}

export default GameArea;
