import React, {useContext} from 'react';
import { View, ImageBackground } from 'react-native';
import commonStyle from '../../styles';
import GameArea from './GameArea'
import PlayerHand from './PlayerHand'
import { GameStateContext } from '../../providers/StateProvider';


const Game = (props) => {
  const { user, gameState } = useContext(GameStateContext);

  // console.log(`gameState table: ${gameState && gameState.activePlayers ? gameState.activePlayers[user.name].name : 'no name'}`)
    function getStyle (){
        return [
          commonStyle.gameArea,
          props.gameStart ? commonStyle.gameStart : commonStyle.gameNotStart,
        ];
      }

    return (
      <ImageBackground
        source={require('../../assets/bg.png')}
        style={{
          flex: 1,
        }}>
        {/* <StatusBar hidden={true} /> */}
        <View style={commonStyle.gameContainer}>
          <View style={getStyle()}>
            {<GameArea deck={gameState ? gameState.tableDeck : []} openDeck={gameState ? gameState.activeDeck : []}/>}
          </View>
          <PlayerHand gameStart={true} playerHand={gameState && gameState.activePlayers && gameState.activePlayers[user.name] ? gameState.activePlayers[user.name].cards :[] }/>
        </View>
        {/* {props.gameStart && this.props.myTurn ? <PlayerMenu /> : null} */}
      </ImageBackground>
    );
}

export default Game;
