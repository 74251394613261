import React, { useContext } from 'react';
import { StyleSheet, ImageBackground, TouchableOpacity, Text } from 'react-native';
import Colors from '../../utils/colors';
import commonStyle from '../../styles';
import SafeView from '../SafeView';
import { AuthUserContext } from '../../navigation/AuthUserProvider';
import { logout } from '../Firebase/firebase';

const AnonSettingsScreen = () => {

  const auth = useContext(AuthUserContext);

  async function handleSignOut() {
    try {
      auth.setSignedOut(true)
      await logout();
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <ImageBackground
      resizeMode="stretch"
      source={require('../../assets/board.png')}
      style={commonStyle.playerArea}>
      <SafeView style={styles.container}>
        <Text style={{ color: "orange", fontSize: 16 }} >
          In order to access more features on the settings panel, consider creating an account
      </Text>
        <TouchableOpacity
          style={[styles.button, { backgroundColor: Colors['primary'] }]}
          onPress={handleSignOut}
        >
          <Text style={styles.buttonText}> Log Out / Register </Text>
        </TouchableOpacity>
      </SafeView>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 50,
    // paddingTop: '50%',
    // backgroundColor: Colors.mediumGrey
  },
  footerButtonContainer: {
    marginVertical: 15,
    justifyContent: 'center',
    alignItems: 'center'
  },
  forgotPasswordButtonText: {
    color: Colors.white,
    fontSize: 18,
    fontWeight: '600'
  },
  backButton: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    marginVertical: 10,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    width: '100%'
  },
  buttonText: {
    color: Colors.white,
    fontSize: 18,
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  listStyle: {
    alignItems: "center",
    paddingTop: 10
  }
});


export default AnonSettingsScreen;
