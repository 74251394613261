import React, {useState, useEffect, useContext} from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';

import AppButton from '../components/AppButton';
import Colors from '../utils/colors';
import useStatusBar from '../hooks/useStatusBar';
import { anonLogin } from '../components/Firebase/firebase';
import FormErrorMessage from '../components/Forms/FormErrorMessage';
import { AuthUserContext } from '../navigation/AuthUserProvider';


export default function WelcomeScreen({ navigation }) {
  useStatusBar('light-content');
  const auth = useContext(AuthUserContext);

  const [loginError, setLoginError] = useState('');

  async function handleAnonLogin() {
    try {
      await anonLogin();
    } catch (error) {
      setLoginError(`anothr ${error.message}`);
    }
  }

  useEffect(()=>{
    if (!auth.signedOut) {
      handleAnonLogin()
    }
  },[auth])

  return (
    <View style={styles.container}>
      <View style={styles.logoContainer}>
        <Image source={require('../assets/flame.png')} style={styles.logo} />
        <Text style={styles.subtitle}>huya titambe maCards</Text>
      </View>
      <View style={styles.buttonContainer}>
        <AppButton title="Login" onPress={() => navigation.navigate('Login')} />
        <AppButton
          title="Register"
          color="secondary"
          onPress={() => navigation.navigate('Register')}
        />
        <Text style={{fontWeight: 'bold', fontSize: 16, color: Colors.primary}}> OR </Text>
        <Text style={{fontWeight: 'bold', color: Colors.primary}}>don't have an account but already have GameID? </Text>
        <AppButton title="Login Anonymously" onPress={handleAnonLogin} />
        <FormErrorMessage error={loginError} visible={true} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: Colors.tintColor
  },
  logoContainer: {
    position: 'absolute',
    top: 60,
    alignItems: 'center'
  },
  logo: {
    width: 125,
    height: 125
  },
  subtitle: {
    fontSize: 24,
    fontWeight: '600',
    paddingVertical: 20,
    color: Colors.primary
  },
  buttonContainer: {
    padding: 20,
    paddingBottom: 60,
    width: '100%',
    alignItems: 'center',
    maxWidth: 400
  }
});
