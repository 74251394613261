import React, { useState, useContext } from 'react';
import { StyleSheet, ImageBackground, TouchableOpacity, Text, ScrollView } from 'react-native';
import * as Yup from 'yup';
import Colors from '../../utils/colors';
import commonStyle from '../../styles';
import SafeView from '../SafeView';
import { Formik } from 'formik';
import FormField from '../Forms/FormField';
import FormErrorMessage from '../Forms/FormErrorMessage';
import { GameStateContext } from '../../providers/StateProvider';
import { AuthUserContext } from '../../navigation/AuthUserProvider';
import { logout } from '../Firebase/firebase';

const validationSchema = Yup.object().shape({
  secret: Yup.string()
    .required('Please enter the secret ID to reset all games')
    .label('Nick-Name'),
  game_id: Yup.string()
    .required('Please enter a game id')
    .label('Game ID')
});

const SettingsScreen = () => {

  const { resetAllGames, resetGame, connect, user, gameState } = useContext(GameStateContext);
  const auth = useContext(AuthUserContext);
  // console.log(`auth: ${JSON.stringify(auth.user.email)}`)

  // let current_player = gameState.next_player ? gameState.next_player : ''
  const other_players = gameState && gameState.activePlayers ? Object.keys(gameState.activePlayers).map((player, idx) => {
    let cards_left = gameState.activePlayers[player].cards.length === 1 ? `${gameState.activePlayers[player].cards.length} card` : `${gameState.activePlayers[player].cards.length} cards`
    // if (current_player && current_player !== user.name) {
    return <Text style={{ color: "orange", fontSize: 16 }} key={idx}>  {player} has {cards_left} left </Text>
    // }
  }) : null
  const allhistory = gameState && gameState.history ? gameState.history.map((message, idx) => <Text style={{ color: "orange", fontSize: 16 }} key={idx}> - {message}</Text>) : null

  async function handleSignOut() {
    try {
      auth.setSignedOut(true)
      await logout();
    } catch (error) {
      console.log(error);
    }
  }

  const handleResetGame = (values) => {
    // console.log(`game_id: s${resetGame} ${JSON.stringify(values.game_id)}`)
    let gameID = values.game_id.toLowerCase().trim()
    try {
      resetGame(gameID)
    } catch (error) {
      setResetError(error.message);
    }
  }
  const handleResetAllGames = (values) => {
    // console.log(`values: ${JSON.stringify(values)}`)
    let password = values.secret.toLowerCase().trim()
    try {
      resetAllGames(password)
    } catch (error) {
      setResetError(error.message);
    }
  }

  const [resetError, setResetError] = useState()

  return (
    <ImageBackground
      resizeMode="stretch"
      source={require('../../assets/board.png')}
      style={commonStyle.playerArea}>
      <SafeView style={styles.container}>
        < SafeView style={styles.listStyle}>
          <ScrollView>
            {other_players}
          </ScrollView>
        </SafeView>
        <Formik
          initialValues={{ game_id: user.game_id || '', secret: '' }}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <>

              <TouchableOpacity
                style={[styles.button, { backgroundColor: Colors['primary'] }]}
                onPress={() => handleResetGame(values)}
              >
                <FormField
                  name="game_id"
                  leftIcon="home"
                  placeholder="Enter GameID"
                  autoCapitalize="none"
                  autoFocus={false}
                />
                <Text style={styles.buttonText}> Reset Game </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.button, { backgroundColor: Colors['primary'] }]}
                onPress={handleSignOut}
              >
                <Text style={styles.buttonText}> Log Out </Text>
              </TouchableOpacity>
              {auth.user.email === "lovemore.jokonya@outlook.com" &&
                <>
                  <TouchableOpacity
                    style={[styles.button, { backgroundColor: Colors['primary'] }]}
                    onPress={() => handleResetAllGames(values)}
                  >
                    <FormField
                      name="secret"
                      leftIcon="lock"
                      placeholder="Enter Secret"
                      autoCapitalize="none"
                      autoFocus={false}
                    />
                    <Text style={styles.buttonText}> Reset All Games </Text>
                  </TouchableOpacity>
                </>}
              {<FormErrorMessage error={resetError} visible={true} />}
            </>)}
        </Formik>
        < SafeView style={styles.listStyle}>
          <Text style={{ color: "white", fontSize: 18 }}> GAME HISTORY</Text>
          <ScrollView >
            {allhistory}
          </ScrollView>
        </SafeView>
      </SafeView>

    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    paddingLeft: 50,
    paddingRight: 50,
    // backgroundColor: Colors.mediumGrey
  },
  footerButtonContainer: {
    marginVertical: 15,
    justifyContent: 'center',
    alignItems: 'center'
  },
  forgotPasswordButtonText: {
    color: Colors.white,
    fontSize: 18,
    fontWeight: '600'
  },
  backButton: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    marginVertical: 10,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    width: '100%'
  },
  buttonText: {
    color: Colors.white,
    fontSize: 18,
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  listStyle: {
    alignItems: "center",
    paddingTop: 10
  }
});


export default SettingsScreen;
