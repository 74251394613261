import React, { useContext } from 'react';
import axios from 'axios'
import Constants from 'expo-constants';
import { View, Text } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { GameStateContext } from '../../providers/StateProvider';
import { AuthUserContext } from '../../navigation/AuthUserProvider';
const REACT_APP_WEBSOCKET_URL = Constants.manifest.extra.PROD_WEBSOCKET_URL
const REACT_APP_HTTP_URL = Constants.manifest.extra.PROD_HTTP_URL


const GameStatusBar = () => {
  const auth = useContext(AuthUserContext);
  const { user, setUser, connect, gameState } = useContext(GameStateContext);

  // console.log(`auth: ${JSON.stringify(auth.user.isAnonymous)}`)
  // console.log(`name on status: ${user.name}, ${user.game_id}, ${gameState && gameState.next_player}`)
  const startGame = (max_cards, game_id) => {
    return axios.post(`${REACT_APP_HTTP_URL}/api/game/start`, { max_cards, game_id })
      .then((res) => {
        // console.log(`game started : ${JSON.stringify(game_id)}`)
      })
  }


const reShuffle = () => {
  // console.log(`${current_player} reshuffled cards:`)
  return axios.post(`${REACT_APP_HTTP_URL}/api/games/reshuffle`, {gameState})
      .then((res)=>{
          // console.log(`res from reshuffle: ${JSON.stringify(res)}`)
      })
}

  const current_player = user ? user.name : ''
  const handleNext = () => {
    // console.log(`clicked: ${name}`)
    return axios.post(`${REACT_APP_HTTP_URL}/api/game/play/next`, { gameState, current_player })
      .then((res) => {
        // console.log(`res from playNext: ${JSON.stringify(res)}`)
        // res.data ? setGameState(res.data) : ''
      })
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        marginTop: 10,
        marginLeft: 10,
        marginRight: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      {/* {!auth.user.isAnonymous && */}
        <View>
          <Icon.Button
            onPress={() => startGame(5, user.game_id)}
            name="ios-flash-outline"
            size={16}
            color="#fff"
            backgroundColor="#231">
            Start Game
            </Icon.Button>
            {gameState && gameState.activeDeck && gameState.activeDeck.length < 2 &&
              <Icon.Button
                onPress={reShuffle}
                name="ios-flash-outline"
                size={16}
                color="#fff"
                backgroundColor="#231">
                reShuffle Cards
              </Icon.Button>
            }
        </View>
      {/* } */}
      <View>
        {gameState &&
          <Text
            style={{
              fontSize: 16,
              color: '#fff',
              maxWidth: 120,
              fontWeight: "bold"
            }}>
            {gameState.message}
          </Text>}
      </View>
      {gameState && gameState.next_player === current_player && gameState.status !== "game ended" && gameState.message === `${current_player} has drawn a card from the deck` ?
        <View>
          {/* <Text style={{ color: "#fff" }}> Your turn or click</Text> */}
          <Icon.Button
            onPress={handleNext}
            // name="logo-usd"
            size={16}
            color="#fff"
            backgroundColor="#231">
            <Text style={{ color: "#fff" }}>Next Please</Text>
          </Icon.Button>
        </View>: 
        gameState && gameState.next_player === current_player && gameState.status !== "game ended" ?
        <View>
          <Icon.Button
            size={16}
            color="#fff"
            backgroundColor="#231">
            <Text style={{ color: "#fff" }}>Your Turn</Text>
          </Icon.Button>
        </View> 
        : null
        }
      { gameState && gameState.next_player && gameState.next_player !== current_player && gameState.status !== "game ended" ?
        <View>
          <Icon.Button
            // name="logo-usd"
            size={16}
            color="#fff"
            backgroundColor="#231">
            <Text style={{ color: "#fff" }}> {gameState.next_player}'s turn </Text>
          </Icon.Button>
        </View>
        : null
      }

        {gameState && gameState.status === "game ended" &&
        <View>
          <Icon.Button
            name="logo-usd"
            size={16}
            color="#fff"
            backgroundColor="#231">
            <Text style={{ color: "#fff"}}>GAME OVER</Text>
          </Icon.Button>
        </View>
        }

    </View>
  );
}

export default GameStatusBar;
