// Rename this file to "firebaseConfig.js" before use
// Replace all XXXXs with real Firebase API keys

export default {
  apiKey: 'AIzaSyCQ0vnwqIPZYLbjYVsH6VCSJ8e0Az5gmVk',
  authDomain: 'https://crazy8-2b1ba.web.app',
  databaseURL: 'XXXX',
  projectId: 'crazy8-2b1ba',
  appId: '1:195764292145:web:d61bf17222982431586310'
};
